import { numberWithSpaces } from "./number-with-spaces"

export interface IFormatMoneyOptions {
  currency?: string | null
  fixed?: number
  round?: "up" | "down"
  plus?: boolean
}

export const formatMoney = (amount?: string | number | null, options?: IFormatMoneyOptions): string => {
  if (amount === undefined || amount === null) return ""
  if (isNaN(Number(amount))) return ""
  const fixed = options?.fixed ?? 2

  let number = Number(amount)
  if (options?.round === "up") number = Math.ceil(number)
  if (options?.round === "down") number = Math.floor(number)

  let num = numberWithSpaces(number.toFixed(fixed))

  if (options?.plus && number > 0) {
    num = "+" + num
  }

  const currency = options?.currency ?? "₽"

  if (!currency) return num

  return num + "\u00A0" + currency
}
