import React, { CSSProperties, useEffect, useMemo, useRef, useState } from "react";
import cx from "classnames";
import styles from "./progress.module.scss";
export interface IProgress {
  value: number;
  className?: string;
  style?: CSSProperties;
  htmlId?: string;
}
const YELLOW_MIN = 11;
const GREEN_MIN = 21;
const texts = {
  red: ["Пожалуйста, опишите подробнее", "Может, есть что добавить?", "Подробный отзыв будет полезнее"],
  yellow: ["Будет еще лучше, если немного дополните", "Расскажете еще немного?", "Будем благодарны, если поделитесь еще"],
  green: ["Отлично, это очень поможет другим покупателям", "Спасибо, что так подробно", "Хорошо подмечено"]
};
export const Progress = (props: IProgress) => {
  const value = props.value;
  const max = GREEN_MIN;
  let color: "red" | "yellow" | "green" | undefined;
  if (!value) {
    color = undefined;
  } else if (value < YELLOW_MIN) {
    color = "red";
  } else if (value < GREEN_MIN) {
    color = "yellow";
  } else {
    color = "green";
  }
  const text = useMemo(() => {
    function getRandomElement<T>(arr: T[]): T {
      const randomIndex = Math.floor(Math.random() * arr.length);
      return arr[randomIndex];
    }
    if (!color) return "";
    return getRandomElement(texts[color]);
  }, [color]);
  const [offset, setOffset] = useState<number>(47);
  const circleRef = useRef<SVGCircleElement>(null);
  const size = 17;
  const strokeWidth = 2;
  const center = size / 2;
  const radius = size / 2 - strokeWidth / 2;
  const circumference = 2 * Math.PI * radius;
  useEffect(() => {
    let progressOffset = (max - Math.min(value, max)) / max * circumference;
    setOffset(progressOffset);
    if (circleRef.current) {
      circleRef.current.style.strokeDashoffset = `${progressOffset}`;
    }
  }, [setOffset, circumference, value, max]);
  if (value === 0) return null;
  return <div className={cx(styles.progress, props.className)} style={props.style} id={props.htmlId} data-sentry-component="Progress" data-sentry-source-file="progress.tsx">
      <svg className={styles.svg} width={size} height={size} data-sentry-element="svg" data-sentry-source-file="progress.tsx">
        <circle className={styles.circleBg} cx={center} cy={center} r={radius} strokeWidth={strokeWidth} data-sentry-element="circle" data-sentry-source-file="progress.tsx" />
        <circle className={cx(styles.circle, color && styles[color])} ref={circleRef} cx={center} cy={center} r={radius} strokeWidth={strokeWidth} strokeDasharray={circumference} strokeDashoffset={offset} data-sentry-element="circle" data-sentry-source-file="progress.tsx" />
      </svg>

      <div className={styles.text}>{text}</div>
    </div>;
};