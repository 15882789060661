"use client";

import { Button } from "..";
import styles from "./modern-modal.module.scss";
import cx from "classnames";
import { useCallback, useEffect, useRef, useState } from "react";
import { IconX } from "@tabler/icons-react";
import { IS_MOBILE } from "shared/lib/environment/user-agent";
interface IModernModal {
  children: React.ReactNode;
  noPadding?: boolean;
  fullHeight?: boolean;
  className?: string;
  overlayClassName?: string;
  title?: React.ReactNode;
  close?: () => void;
  hideCloseButton?: boolean;
}
export const ModernModal = (props: IModernModal) => {
  const ref = useRef<HTMLDivElement>(null);
  const [close, setClose] = useState(false);
  const onClose = useCallback(() => setClose(true), [setClose]);
  useEffect(() => {
    if (close) {
      if (IS_MOBILE) {
        const timeout = setTimeout(() => props.close?.(), 300);
        return () => clearTimeout(timeout);
      } else {
        onClose();
        const timeout = setTimeout(() => props.close?.(), 300);
        return () => clearTimeout(timeout);
      }
    }
  }, [close]);

  // useKeyDown(KeyName.ESC, onClose)

  const firstRender = useRef(true);
  useEffect(() => {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
      if (firstRender.current) {
        firstRender.current = false;
        return;
      }
    }
    const scrollTop = document.documentElement.scrollTop;
    const maxScrollTop = document.documentElement.scrollHeight - document.documentElement.clientHeight;
    document.documentElement.scrollTo({
      top: 0,
      behavior: "instant"
    });
    document.body.style.position = `fixed`;
    document.body.style.width = `100%`;
    document.body.style.top = `-${scrollTop}px`;
    document.body.style.overflow = "hidden";
    if (scrollTop > maxScrollTop) {
      requestAnimationFrame(() => {
        document.body.style.transition = "top 0.4s";
        document.body.style.top = `-${maxScrollTop}px`;
      });
    }

    // doubleRaf().then(() => {
    //   document.body.style.removeProperty("position")
    //   document.body.style.removeProperty("top")
    //   document.documentElement.scrollTo({ top: scrollTop, behavior: "instant" })
    // })

    return () => {
      document.body.style.removeProperty("transition");
      document.body.style.removeProperty("position");
      document.body.style.removeProperty("width");
      document.body.style.removeProperty("top");
      document.documentElement.scrollTo({
        top: scrollTop,
        behavior: "instant"
      });
      document.body.style.removeProperty("overflow");
    };
  }, []);
  return <div className={styles.container} data-sentry-component="ModernModal" data-sentry-source-file="modern-modal.tsx">
      <div role="button" className={cx(styles.overlay, IS_MOBILE && close && styles.close, !IS_MOBILE && close && styles.close_desktop, props.overlayClassName)} onClick={onClose} ref={ref} />
      <div role="dialog" className={styles.modal}>
        <div className={cx(styles.content, props.noPadding && styles.no_padding, props.fullHeight && styles.full_height, IS_MOBILE && close && styles.close, !IS_MOBILE && close && styles.close_desktop, props.className)}>
          {!props.hideCloseButton && <Button className={styles.close_button} onClick={onClose}>
              <IconX stroke={1} />
            </Button>}

          {props.title && <div className={styles.heading}>{props.title && <h2 className={styles.title}>{props.title}</h2>}</div>}
          {props.children}
        </div>
      </div>
    </div>;
};