export class SessionStorage {
  static getItem<T = string>(key: string): T | undefined {
    if (typeof sessionStorage === "undefined" || !sessionStorage) return undefined

    return sessionStorage.getItem(key) as any
  }

  static setItem(key: string, value: string | undefined): void {
    if (typeof sessionStorage === "undefined" || !sessionStorage) return undefined

    if (value !== undefined) {
      sessionStorage.setItem(key, value)
    } else {
      sessionStorage.removeItem(key)
    }
    SessionStorage.notify(key, value)
  }

  static removeItem(key: string): void {
    if (typeof sessionStorage === "undefined" || !sessionStorage) return undefined

    sessionStorage.removeItem(key)
    SessionStorage.notify(key, undefined)
  }

  static getJson<T = any>(key: string): T | undefined {
    const raw = SessionStorage.getItem(key)
    if (!raw) return undefined
    return JSON.parse(raw)
  }

  static setJSON(key: string, json: any): void {
    const value = JSON.stringify(json)
    SessionStorage.setItem(key, value)
  }

  private static subscribers: { [key: string]: ((value: string | undefined) => void)[] } = {}

  static subscribe = (key: string, fn: (value: string | undefined) => void) => {
    const unsubscribeFromCurrentPage = SessionStorage.subscribeToCurrentTab(key, fn)
    const unsubscribeFromOtherTabs = SessionStorage.subscribeToOtherTabs(key, fn)

    return () => {
      unsubscribeFromCurrentPage()
      unsubscribeFromOtherTabs()
    }
  }

  private static notify = (key: string, value: string | undefined) => {
    for (const it of SessionStorage.subscribers[key] || []) {
      it(value)
    }
  }

  static subscribeToCurrentTab = (key: string, fn: (value: string | undefined) => void) => {
    if (!SessionStorage.subscribers[key]) {
      SessionStorage.subscribers[key] = []
    }

    SessionStorage.subscribers[key].push(fn)

    return () => {
      SessionStorage.subscribers[key] = SessionStorage.subscribers[key]?.filter((it) => it !== fn)
    }
  }

  static subscribeToOtherTabs = (key: string, fn: (value: string | undefined) => void): (() => void) => {
    if (typeof window === "undefined") return () => {}

    const listener = (e: StorageEvent) => {
      // after sessionStorage.clear()
      if (e.key == null) {
        fn(undefined)
      }

      if (e.key === key) {
        fn(e.newValue || undefined)
      }
    }

    window.addEventListener("storage", listener)

    return () => {
      window.removeEventListener("storage", listener)
    }
  }
}
