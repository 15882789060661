"use client";

import cx from "classnames";
import React, { CSSProperties } from "react";
import styles from "./switch.module.scss";
export interface ISwitch {
  children?: React.ReactNode;
  checked?: boolean;
  disabled?: boolean;
  onChange?: (value: boolean) => void;
  className?: string;
  style?: CSSProperties;
  htmlId?: string;
}
export const Switch = (props: ISwitch) => {
  return <label className={cx(styles.switch, props.disabled && styles.disabled, props.className)} style={props.style} id={props.htmlId} data-sentry-component="Switch" data-sentry-source-file="switch.tsx">
      <span className={styles.box}>
        <span className={cx(styles.check, props.disabled && styles.disabled, props.checked && styles.checked)}>
          <input type="checkbox" checked={props.checked} onChange={e => props.onChange?.(e.target.checked)} disabled={props.disabled} />
          <span className={styles.icon}></span>
        </span>
      </span>
      {props.children && <span className={cx(styles.text)}>{props.children}</span>}
    </label>;
};