import { capitalize } from "shared/lib/capitalize"
import { ApiError } from "shared/api/back/_generated"

export const getErrorMessage = async (e: unknown): Promise<{ type: "custom"; message: string; status?: number }> => {
  if (e instanceof ApiError) {
    try {
      const json = await e.response.json()

      if (typeof json !== "object" || typeof json.message !== "string") {
        console.error(json)
        throw new Error()
      }

      const type = typeof json.type === "string" ? json.type : "custom"
      let message = capitalize(json.message)!

      if (!message.endsWith(".")) {
        message += "."
      }

      return { type, message, status: e.response.status }
    } catch (ignore) {
      return { type: "custom", message: e.response.statusText, status: e.response.status }
    }
  } else if (e instanceof Error) {
    return { type: "custom", message: e.message }
  } else {
    return {
      type: "custom",
      message: "Неизвестная ошибка. Попробуйте еще раз, если не получится — обратитесь в поддержку.",
    }
  }
}
