import React, { CSSProperties, SetStateAction, useEffect, useRef, useState } from "react";
import { api, ImageDto } from "shared/api";
import cx from "classnames";
import styles from "./review-image-array-input.module.scss";
import { IconCamera, IconReload, IconX } from "@tabler/icons-react";
import { analytics } from "entity/analytics";
import { getErrorMessage } from "shared/lib";
import Image from "next/image";
interface UploadedImage {
  file?: File;
  preview?: string;
  uploading?: boolean;
  error?: boolean;
  id?: string;
  url?: string;
}
export interface IReviewImageArrayInput {
  className?: string;
  style?: CSSProperties;
  htmlId?: string;
  initValues?: ImageDto[];
  onChange: (value: string[]) => void;
  analytics_id?: string;
}
export const ReviewImageArrayInput = (props: IReviewImageArrayInput) => {
  const [images, _setImages] = useState<UploadedImage[]>(() => props.initValues?.map(it => ({
    ...it
  })) || []);
  const [hasUpdates, setHasUpdates] = useState(false);
  const setImages = (value: SetStateAction<UploadedImage[]>) => {
    _setImages(value);
    setHasUpdates(true);
  };
  useEffect(() => {
    if (hasUpdates) {
      const ids = images.filter(it => it.id).map(it => it.id!);
      props.onChange(ids);
    }
  }, [images, hasUpdates]);
  const handleAddPhoto = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (!files) return;
    if (props.analytics_id) analytics.send(props.analytics_id + ".images_selected", {
      count: files.length
    });
    const newImages = Array.from(files).map(file => ({
      file,
      preview: URL.createObjectURL(file),
      uploading: true
    }));
    setImages(prevImages => [...prevImages, ...newImages]);
    event.target.value = "";
    for (const image of newImages) {
      try {
        if (props.analytics_id) analytics.send(props.analytics_id + ".image.add");
        const response = await api.image.uploadImage(image.file);
        if (props.analytics_id) analytics.send(props.analytics_id + ".image.loaded");
        setImages(prevImages => prevImages.map(img => img.preview === image.preview ? {
          ...img,
          url: response.url,
          id: response.id,
          uploading: false
        } : img));
      } catch (error) {
        if (props.analytics_id) analytics.send(props.analytics_id + ".image.error", getErrorMessage(error));
        setImages(prevImages => prevImages.map(img => img.preview === image.preview ? {
          ...img,
          uploading: false,
          error: true
        } : img));
      }
    }
  };
  const handleRetryUpload = async (image: UploadedImage) => {
    if (props.analytics_id) analytics.send(props.analytics_id + ".image.retry");
    setImages(prevImages => prevImages.map(img => img.preview === image.preview ? {
      ...img,
      uploading: true,
      error: false
    } : img));
    try {
      const response = await api.image.uploadImage(image.file);
      if (props.analytics_id) analytics.send(props.analytics_id + ".image.loaded");
      setImages(prevImages => prevImages.map(img => img.preview === image.preview ? {
        ...img,
        ...response,
        uploading: false
      } : img));
    } catch (error) {
      if (props.analytics_id) analytics.send(props.analytics_id + ".image.error", getErrorMessage(error));
      setImages(prevImages => prevImages.map(img => img.preview === image.preview ? {
        ...img,
        uploading: false,
        error: true
      } : img));
    }
  };
  const handleRemoveImageByIndex = (index: number) => {
    if (props.analytics_id) analytics.send(props.analytics_id + ".image.remove");
    setImages(prevImages => prevImages.filter((img, key) => key !== index));
  };
  const imageInputRef = useRef<HTMLInputElement>(null);
  return <div className={cx(styles.review_image_array_input, props.className)} style={props.style} id={props.htmlId} data-sentry-component="ReviewImageArrayInput" data-sentry-source-file="review-image-array-input.tsx">
      {!!images.length && <div className={styles.imageList}>
          {images.map((image, index) => <div key={image.id || image.preview || index} className={cx(styles.imageWrapper, image.error && styles.error)}>
              {!image.error && <div className={styles.progressBar}></div>}

              {image.error && <button className={styles.retryButton} onClick={() => handleRetryUpload(image)}>
                  <IconReload size={24} stroke={1.5} />
                  еще&nbsp;раз
                </button>}

              {!image.error && <Image src={image.preview || image.url!} width={70} height={70} alt="" className={cx(styles.image, image.uploading && styles.loading)} />}

              <button className={styles.removeButton} onClick={() => handleRemoveImageByIndex(index)}>
                <IconX stroke={2} size={18} />
              </button>
            </div>)}
        </div>}

      <input type="file" multiple onChange={handleAddPhoto} className={styles.fileInput} ref={imageInputRef} />

      <button className={styles.button} onClick={() => {
      if (props.analytics_id) analytics.send(props.analytics_id + ".add_photo_button.click");
      imageInputRef.current?.click();
    }}>
        <IconCamera stroke={1.5} data-sentry-element="IconCamera" data-sentry-source-file="review-image-array-input.tsx" />
        Добавить фото
      </button>
    </div>;
};