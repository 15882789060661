import type { ElementOrSelector } from "shared/lib/use-in-view/types"

export function resolveElements(elements: ElementOrSelector): Element[] {
  if (typeof elements === "string") {
    elements = document.querySelectorAll(elements)
  } else if (elements instanceof Element) {
    elements = [elements]
  }

  return Array.from(elements || [])
}
