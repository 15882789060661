import { RefObject, useEffect, useState } from "react"
import type { InViewOptions } from "./in-view"
import { inView } from "./in-view"

export interface UseInViewOptions extends Omit<InViewOptions, "root" | "amount"> {
  root?: RefObject<Element>
  once?: boolean
  amount?: "some" | "all" | number
}

export function useInView(ref: RefObject<Element>, { root, margin, amount, once = false }: UseInViewOptions = {}) {
  const [isInView, setInView] = useState(false)

  useEffect(() => {
    if (!ref.current || (once && isInView)) return

    const onEnter = () => {
      setInView(true)

      return once ? undefined : () => setInView(false)
    }

    const options: InViewOptions = {
      root: (root && root.current) || undefined,
      margin,
      amount,
    }

    return inView(ref.current, onEnter, options)
  }, [root, ref, margin, once, amount])

  return isInView
}
