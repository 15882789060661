"use client"

const navigator = typeof window !== "undefined" ? window.navigator : null

export const USER_AGENT = navigator ? navigator.userAgent : null

export const IS_SAFARI =
  typeof window !== "undefined" &&
  ("safari" in window ||
    !!(
      USER_AGENT &&
      (/\b(iPad|iPhone|iPod)\b/.test(USER_AGENT) || (!!USER_AGENT.match("Safari") && !USER_AGENT.match("Chrome")))
    )) /*  || true */

export const IS_MOBILE =
  !!navigator &&
  (navigator?.maxTouchPoints === undefined || navigator.maxTouchPoints > 0) &&
  navigator?.userAgent.search(
    /iOS|iPhone OS|Android|BlackBerry|BB10|Series ?[64]0|J2ME|MIDP|opera mini|opera mobi|mobi.+Gecko|Windows Phone/i,
  ) != -1
