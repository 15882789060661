"use client"
import { useEffect, useState } from "react"

export const useHandleResize = () => {
  const [width, setWidth] = useState(0)

  const handleResize = async () => {
    setWidth(window.innerWidth)
  }

  useEffect(() => {
    handleResize()
    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  }, [])

  return width
}
