import React, { CSSProperties } from "react";
import cx from "classnames";
import styles from "./stars-input.module.scss";
import { IconStarFilled } from "@tabler/icons-react";
export interface IStarsInput {
  value?: number;
  onChange?: (value: number) => void;
  size?: number;
  className?: string;
  style?: CSSProperties;
  htmlId?: string;
}
export const StarsInput = (props: IStarsInput) => {
  return <div className={cx(styles.stars_input, props.className)} style={props.style} id={props.htmlId} data-sentry-component="StarsInput" data-sentry-source-file="stars-input.tsx">
      {Array.from({
      length: 5
    }).map((it, index) => <div key={index} className={cx(styles.star,
    // props.value === undefined && styles.placeholder,
    index < props.value! && styles.on)} onClick={e => {
      e.preventDefault();
      e.stopPropagation();
      e.nativeEvent.stopImmediatePropagation();
      props.onChange?.(index + 1);
    }}>
          <IconStarFilled size={props.size ?? 40} />
        </div>)}
    </div>;
};