import React, { useEffect } from "react";
import cx from "classnames";
import styles from "./close-confirm-modal.module.scss";
import { Modals, UseModalProps } from "shared/lib";
import { Button, ModernModal } from "shared/ui";
import { analytics } from "entity/analytics";
export interface ICloseConfirmModal extends UseModalProps {
  onLeave: () => void;
  onSave: () => void;
}
export const CloseConfirmModal = (props: ICloseConfirmModal) => {
  useEffect(() => {
    analytics.send("review_form.close_confirm.open");
    return () => analytics.send("review_form.close_confirm.close");
  });
  const onLeave = () => {
    analytics.send("review_form.close_confirm.leave_button.click");
    props.close();
    props.onLeave();
  };
  const onSave = async () => {
    analytics.send("review_form.close_confirm.save_button.click");
    await props.onSave();
    props.close();
  };
  return <ModernModal close={props.close} className={cx(styles.close_confirm_modal)} hideCloseButton data-sentry-element="ModernModal" data-sentry-component="CloseConfirmModal" data-sentry-source-file="close-confirm-modal.tsx">
      <div className={styles.content}>
        <div className={styles.title}>Опубликовать отзыв?</div>

        <div className={styles.description}>
          Ваша оценка очень важна: она поможет другим пользователям определиться с выбором
        </div>

        <div className={styles.actions}>
          <Button className={cx(styles.button)} onClick={onLeave} data-sentry-element="Button" data-sentry-source-file="close-confirm-modal.tsx">
            Нет, просто уйти
          </Button>
          <Button className={cx(styles.button, styles.primary)} onClick={onSave} data-sentry-element="Button" data-sentry-source-file="close-confirm-modal.tsx">
            Опубликовать
          </Button>
        </div>
      </div>
    </ModernModal>;
};
CloseConfirmModal.open = (props: Omit<ICloseConfirmModal, keyof UseModalProps>) => Modals.open(CloseConfirmModal, props);