"use client"

import { useHandleResize } from "./use-handle-resize"

export const useMediaQuery = () => {
  const width = useHandleResize()

  return {
    mobile: width <= 578,
    tablet: width <= 768,
    laptop: width <= 992,
  }
}
