import { CSSProperties, useState } from "react";
import { Switch } from "shared/ui";
import cx from "classnames";
import styles from "./review-switch.module.scss";
interface IReviewSwitch {
  className?: string;
  style?: CSSProperties;
  htmlId?: string;
  onChange: (value: boolean) => void;
  value?: boolean;
  name?: string;
  label?: string;
}
export const ReviewSwitch = (props: IReviewSwitch) => {
  return <div id={props.htmlId} className={cx(props.className, styles.review_switch)} style={props.style} data-sentry-component="ReviewSwitch" data-sentry-source-file="review-switch.tsx">
      <Switch htmlId={props.name} onChange={value => props.onChange(value)} checked={props.value} data-sentry-element="Switch" data-sentry-source-file="review-switch.tsx">
        <div className={styles.label}>{props.label}</div>
      </Switch>
    </div>;
};