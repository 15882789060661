"use client";

import React, { CSSProperties, useEffect, useMemo, useState } from "react";
import { IconX } from "@tabler/icons-react";
import { api, CustomerOrderReviewDto, OrderStatus } from "shared/api";
import { ReviewProduct } from "./components/product";
import { StarsInput } from "./components/stars-input/stars-input";
import { ReviewTextInput } from "./components/review-text-input";
import { CheckoutHeader } from "app/(product)/checkout/[spu]/components-new/header";
import { ReviewImageArrayInput } from "./components/review-image-array-input";
import cx from "classnames";
import Image from "next/image";
import { Button } from "shared/ui";
import { CloseConfirmModal } from "./close-confirm-modal";
import { analytics } from "entity/analytics";
import styles from "./review-form.module.scss";
import { formatMoney, getErrorMessage, KeyName, numberWithSpaces, useKeyDown, useMediaQuery } from "shared/lib";
import { SuccessReviewModal } from "./success-review-modal";
import { SuccessWithOrdersReviewModal } from "./success-with-orders-review-modal";
import { ReviewSwitch } from "./components/review-switch";
export interface IReviewForm {
  orderId: string;
  initScore?: number;
  review?: CustomerOrderReviewDto;
  onClose: () => void;
  mutate?: () => void;
  isPage?: boolean;
  className?: string;
  style?: CSSProperties;
  htmlId?: string;
}
export const ReviewForm = (props: IReviewForm) => {
  const {
    tablet
  } = useMediaQuery();
  const orderId = props.orderId;
  const order = api.order.useOrder(orderId, {
    errorRetryInterval: 500
  });
  const completedOrders = api.order.useOrders([OrderStatus.COMPLETED]);
  const [success, setSuccess] = useState(false);
  const [hide, setHide] = useState(false);
  const ordersWithoutReview = useMemo(() => {
    if (!tablet) return completedOrders.data?.filter(it => !it.review).slice(0, 4) || [];
    return completedOrders.data?.filter(it => !it.review) || [];
  }, [completedOrders.data, tablet, success]);
  const init = props.review || order.data?.review;
  const [updates, setUpdates] = useState<Partial<CustomerOrderReviewDto>>(() => props.initScore ? {
    score: props.initScore
  } : {});
  const values = useMemo(() => ({
    ...init,
    ...updates
  }), [init, updates]);
  const showInputs = !!values.score;
  useEffect(() => {
    analytics.send("review_form.view");
  }, []);
  const onClose = () => {
    if (success && ordersWithoutReview.length || success) return null;
    analytics.send("review_form.close_button.click");
    if (Object.keys(updates).length === 0) {
      props.onClose();
      return;
    }
    CloseConfirmModal.open({
      onLeave: () => onSaveOnlyScore(),
      onSave: () => onSubmit()
    });
  };
  useKeyDown(KeyName.ESC, onClose);
  const onSaveOnlyScore = async () => {
    if (!values.score) return;
    const score = values.score;
    const mutate = props.mutate;
    const fn = async () => {
      try {
        await api.customerOrderReview.customerOrderReviewCreate({
          customerOrderId: orderId,
          score: score,
          advantages: "",
          disadvantages: "",
          comment: "",
          imageIds: [],
          anonymous: false
        });
        analytics.send("review_form.submit.success", {
          orderId,
          score: values.score
        });
        mutate?.();
      } catch (error) {
        analytics.send("review_form.submit.error", getErrorMessage(error));
        throw error;
      }
    };
    fn();
    props.onClose();
  };
  const onSubmit = async () => {
    if (!values.score) return;
    try {
      await api.customerOrderReview.customerOrderReviewCreate({
        customerOrderId: orderId,
        score: values.score,
        advantages: values.advantages || "",
        disadvantages: values.disadvantages || "",
        comment: values.comment || "",
        imageIds: values.imageIds || [],
        anonymous: values.anonymous || false
      }).then(async () => {
        await completedOrders.mutate();
        props.mutate?.();
        order.mutate();
      });
      analytics.send("review_form.submit.success", {
        orderId,
        score: values.score,
        advantages: values.advantages,
        disadvantages: values.disadvantages,
        comment: values.comment,
        imageIds: values.imageIds
      });
    } catch (error) {
      analytics.send("review_form.submit.error", getErrorMessage(error));
      throw error;
    }
    setSuccess(true);
    if (values.advantages || values.disadvantages || values.comment || values.imageIds) {} else {
      if (!ordersWithoutReview.length) {
        props.onClose();
      }
    }
  };
  const onClickReviewInput = () => {
    setSuccess(false);
    setHide(true);
  };
  if (hide) return null;
  if (!order.data) {
    return <div className={styles.loader_block}>
        <div className={styles.loader}></div>
      </div>;
  }
  if (success && ordersWithoutReview.length) {
    return <SuccessWithOrdersReviewModal onClose={props.onClose} mutate={props.mutate} onClickReviewInput={onClickReviewInput} className={props.className} style={props.style} htmlId={props.htmlId} orders={ordersWithoutReview} pointsForReview={((values.advantages || values.disadvantages || values.comment) && order.data?.pointsForReviewText || 0) + (values.imageIds?.length && order.data?.pointsForReviewPhotos || 0)} showPointsForReview={!!(values.advantages || values.disadvantages || values.comment || values.imageIds?.length)} />;
  }
  if (success) {
    return <SuccessReviewModal onClose={props.onClose} className={props.className} style={props.style} htmlId={props.htmlId} />;
  }
  return <div className={cx(styles.review_form, props.isPage && styles.review_page, props.className)} style={props.style} id={props.htmlId} data-sentry-component="ReviewForm" data-sentry-source-file="review-form.tsx">
      <div className={styles.head}>
        <div className={styles.close} onClick={onClose}>
          <IconX stroke={2} data-sentry-element="IconX" data-sentry-source-file="review-form.tsx" />
        </div>

        <CheckoutHeader data-sentry-element="CheckoutHeader" data-sentry-source-file="review-form.tsx">Как вам товар?</CheckoutHeader>
      </div>

      <ReviewProduct order={order.data} className={styles.product} data-sentry-element="ReviewProduct" data-sentry-source-file="review-form.tsx" />

      <div className={cx(styles.stars_container, !showInputs && styles.accent)}>
        <StarsInput value={values.score} onChange={score => {
        analytics.send("review_form.score_input.update", {
          value: score
        });
        setUpdates(updates => ({
          ...updates,
          score
        }));
      }} data-sentry-element="StarsInput" data-sentry-source-file="review-form.tsx" />
      </div>

      <div className={cx(styles.comments_container, !showInputs && styles.hidden)}>
        {!!order.data?.pointsForReviewText && <div className={styles.comments_container_description}>
            <span className={styles.points_text}>{numberWithSpaces(order.data?.pointsForReviewText)}₽</span> за
            подробный отзыв{" "}
            {!!order.data?.pointsForReviewPhotos && <>
                и {<span className={styles.points_text}>{numberWithSpaces(order.data?.pointsForReviewPhotos)}₽</span>}{" "}
                за фото
              </>}
          </div>}
        {!order.data?.pointsForReviewText && !!order.data?.pointsForReviewPhotos && <>
            <span className={styles.points_text}>{formatMoney(order.data?.pointsForReviewPhotos)}</span>
            за фото
          </>}

        <ReviewTextInput label="Достоинства" value={values.advantages} onChange={advantages => setUpdates(updates => ({
        ...updates,
        advantages
      }))} analytics_id="review_form.advantages_input" data-sentry-element="ReviewTextInput" data-sentry-source-file="review-form.tsx" />
        <ReviewTextInput label="Недостатки" value={values.disadvantages} onChange={disadvantages => setUpdates(updates => ({
        ...updates,
        disadvantages
      }))} analytics_id="review_form.disadvantages_input" data-sentry-element="ReviewTextInput" data-sentry-source-file="review-form.tsx" />
        <ReviewTextInput label="Комментарий" description={<>
              Например, ваши ожидания, впечатления, сколько пользуетесь, радует&nbsp;ли вас товар
              и&nbsp;посоветуете&nbsp;ли другим
            </>} value={values.comment} onChange={comment => setUpdates(updates => ({
        ...updates,
        comment
      }))} analytics_id="review_form.comment_input" data-sentry-element="ReviewTextInput" data-sentry-source-file="review-form.tsx" />

        <ReviewImageArrayInput className={styles.photo} initValues={init?.images} onChange={imageIds => setUpdates(updates => ({
        ...updates,
        imageIds
      }))} analytics_id="review_form.images_input" data-sentry-element="ReviewImageArrayInput" data-sentry-source-file="review-form.tsx" />

        <ReviewSwitch onChange={value => {
        analytics.send("review_form.anonymous_switch.update", {
          value
        });
        setUpdates(updates => ({
          ...updates,
          anonymous: value
        }));
      }} value={values.anonymous} htmlId="anonymous" name="anonymous" label="Анонимный отзыв" data-sentry-element="ReviewSwitch" data-sentry-source-file="review-form.tsx" />
      </div>

      {showInputs && <div className={styles.actions}>
          <Button className={styles.button} onClick={async () => {
        analytics.send("review_form.submit_button.click");
        await onSubmit();
      }}>
            Отправить отзыв
          </Button>
        </div>}
      {/*<button className={styles.button}>Отправить отзыв</button>*/}
    </div>;
};